import React from 'react';
import './RemainingDays.css';

const RemainingDays = ({ days, hours, translations }) => {
  let dayText = translations?.message?.days ? translations.message.days :'days';
  let hourText = translations?.message?.hours ? translations.message.hours :'hours';
  let timeText = translations?.message?.leftAnd ? translations.message.leftAnd :'left and';
  let remainingDaysStyle = {
    color: '#1B2559',
  };

  if (days === 0) {
    dayText = '';
    timeText = '';
  } else if (days < 0) {
    days = Math.abs(days);
    timeText = translations?.message?.ago ? translations.message.ago : 'ago';
    if (days === 1) {
      dayText = translations?.message?.day ? translations.message.day : 'day';
    }
  } else if (days === 1) {
    dayText = translations?.message?.day ? translations.message.day : 'day';
  }

  if (hours === 1) {
    hourText = translations?.message?.hour ? translations.message.hour : 'hour';
  }

  if (days < 5) {
    remainingDaysStyle.color = '#EC5252';
  }

  return (
    <div className="remaining-days" style={remainingDaysStyle}>
      {days > 0 && `${days} ${dayText} `}
      {days > 0 && timeText && `${timeText} `}
      {hours > 0 && `${hours} ${hourText}`}
    </div>
  );
};

export default RemainingDays;