import React, { useState } from 'react';
import axios from 'axios';
import './RegisterForm.css';
import { Container, Row, Col } from 'react-bootstrap';
import PhoneInput from "react-phone-number-input";
import fr from 'react-phone-number-input/locale/fr';
import en from 'react-phone-number-input/locale/en';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { SpinnerLoading } from "../../../Dashboard/Components";

const RegisterForm = ({ url, data, translations, language }) => {
    const registerSection = data?.find(item => item.section === "Register Form");
    const { industry_types = [], company_sizes = [], jurisdictions = [] } = (registerSection?.data?.content) || {};

    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        phoneNumber: "",
        email: "",
        organization: "",
        interest: "",
        industryId: "", 
        companySize: "",
        note: "",
        profilePicture: null,
        profilePictureBase64: "",
        profilePictureName: "",
        jurisdictions: ""
    });

    const [formErrors, setFormErrors] = useState({});
    const [registrationMessage, setRegistrationMessage] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const navigate = useNavigate();

    const handleInputChange = (e) => {
        const { name, value, files } = e.target;
        if (name === 'profilePicture' && files.length > 0) {
            const file = files[0];
            setFormData((prevFormData) => ({
                ...prevFormData,
                profilePicture: file,
                profilePictureName: file.name
            }));
            convertFileToBase64(file);
        } else {
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: value
            }));
        }
    };

    const handleIndustryChange = (e) => {
        const selectedIndustry = industry_types.find(industry => industry.id === e.target.value);
        setFormData((prevFormData) => ({
            ...prevFormData,
            industryId: selectedIndustry ? selectedIndustry.id : ""
        }));
    };

    const convertFileToBase64 = (file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            setFormData((prevFormData) => ({
                ...prevFormData,
                profilePictureBase64: reader.result
            }));
        };
    };

    const handlePhoneChange = (value) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            phoneNumber: value
        }));
    };

    const validateForm = () => {
        const errors = {};
        if (!formData.firstName) {
            errors.firstName = translations?.message?.reguiredFirstName || "First name is required";
        }
        if (!formData.lastName) {
            errors.lastName = translations?.message?.reguiredLastName || "Last name is required";
        }
        if (!formData.phoneNumber) {
            errors.phoneNumber = translations?.message?.reguiredPhone || "Phone number is required";
        }
        if (!formData.email) {
            errors.email = translations?.message?.reguiredEmail || "Email is required";
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            errors.email = translations?.message?.reguiredEmail || "Email address is invalid";
        }
        if (!formData.organization) {
            errors.organization = translations?.message?.reguiredOrganization || "Organization is required";
        }
        if (!formData.industryId) {
            errors.industry = translations?.message?.reguiredIndustry || "Industry is required";
        }
        if (!formData.companySize) {
            errors.companySize = translations?.message?.reguiredCompanySize || "Company size is required";
        }
        if (!formData.jurisdictions) {
            errors.jurisdictions = translations?.message?.reguiredJurisdiction || "Jurisdiction is required";
        }
        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) {
            return;
        }
        setIsSubmitting(true);
        const apiUrl = `${url}/api/method/erpnext.selling.doctype.customer.customer.company`;

        const data = {
            first_name: formData.firstName,
            last_name: formData.lastName,
            company_size: formData.companySize,
            company_name: formData.organization,
            location: formData.note,
            email: formData.email,
            phone_number: formData.phoneNumber,
            industry_id: formData.industryId,
            jurisdiction_id: formData.jurisdictions, // Add jurisdiction ID here
            logo: {
                filename: formData.profilePictureName,
                data: formData.profilePictureBase64
            }
        };

        try {
            const response = await axios.post(apiUrl, data, {
                headers: {
                    'Content-Type': 'application/json'
                },
            });

            if (response.data && response.data.status_code === 200) {
                navigate('/checkemail', { state: { email: formData.email } });
            } else {
                setRegistrationMessage(response.data.message || 'Unexpected error occurred. Please try again.');
            }
        } catch (error) {
            if (error.response && error.response.data) {
                setRegistrationMessage(error.response.data.message || 'Registration failed. Please try again.');
            } else {
                setRegistrationMessage(translations?.message?.registrationFailedPleaseTryAgain || 'Registration failed. Please try again.');
            }
        } finally {
            setIsSubmitting(false);
        }
    };


    return (
        <div>
            {registrationMessage && <p className="register-error">{registrationMessage}</p>} {/* Error message at the top */}
            <form className="book-demo-form" onSubmit={handleSubmit}>
                {isSubmitting &&
                    <div className='loading-form'>
                        <SpinnerLoading />
                    </div>
                }
                <Row>
                    <Col md={6}>
                        <div className="contact-input">
                            <p className="contact-input-title">
                                {translations?.message?.firstName || "First Name"} <span>*</span>
                            </p>
                            <input
                                type="text"
                                className="contact-input-text"
                                placeholder={translations?.message?.firstName || "First Name"}
                                name="firstName"
                                value={formData.firstName}
                                onChange={handleInputChange}
                            />
                            {formErrors.firstName && <span className="register-error">{formErrors.firstName}</span>}
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="contact-input">
                            <p className="contact-input-title">
                                {translations?.message?.lastName || "Last Name"} <span>*</span>
                            </p>
                            <input
                                type="text"
                                className="contact-input-text"
                                placeholder={translations?.message?.lastName || "Last Name"}
                                name="lastName"
                                value={formData.lastName}
                                onChange={handleInputChange}
                            />
                            {formErrors.lastName && <span className="register-error">{formErrors.lastName}</span>}
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="contact-input">
                            <p className="contact-input-title">
                                {translations?.message?.phoneNumber || "Phone Number"} <span>*</span>
                            </p>
                            <PhoneInput
                                defaultCountry={"CA"}
                                labels={language === "fr" ? fr : en}
                                international={true}
                                className="contact-input-text"
                                value={formData.phoneNumber}
                                onChange={handlePhoneChange}
                            />
                            {formErrors.phoneNumber && <span className="register-error">{formErrors.phoneNumber}</span>}
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="contact-input">
                            <p className="contact-input-title">
                                {translations?.message?.emailAddress || "Email Address"} <span>*</span>
                            </p>
                            <input
                                type="text"
                                className="contact-input-text"
                                placeholder={translations?.message?.emailHint || "yourName@email.com"}
                                name="email"
                                value={formData.email}
                                onChange={handleInputChange}
                            />
                            {formErrors.email && <span className="register-error">{formErrors.email}</span>}
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="contact-input">
                            <p className="contact-input-title">
                                {translations?.message?.organization || "Organization"} <span>*</span>
                            </p>
                            <input
                                type="text"
                                className="contact-input-text"
                                placeholder={translations?.message?.organization || "Organization"}
                                name="organization"
                                value={formData.organization}
                                onChange={handleInputChange}
                            />
                            {formErrors.organization && <span className="register-error">{formErrors.organization}</span>}
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="contact-input">
                            <p className="contact-input-title">
                                {translations?.message?.companyLogo || "Company Logo"} <span>*</span>
                            </p>
                            <input
                                type="file"
                                className="contact-input-text"
                                name="profilePicture"
                                onChange={handleInputChange}
                            />
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="contact-input">
                            <p className="contact-input-title">
                                {translations?.message?.industry || "Industry"} <span>*</span>
                            </p>
                            <select
                                className="contact-input-text"
                                name="industry"
                                value={formData.industryId}
                                onChange={handleIndustryChange}  // Change to handleIndustryChange
                            >
                                <option value="">{translations?.message?.selectOne || "Select one"}</option>
                                {industry_types.map((industry) => (
                                    <option key={industry.id} value={industry.id}>
                                        {industry.name}
                                    </option>
                                ))}
                            </select>
                            {formErrors.industry && <span className="register-error">{formErrors.industry}</span>}
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="contact-input">
                            <p className="contact-input-title">
                                {translations?.message?.companySize || "Company Size"} <span>*</span>
                            </p>
                            <select
                                className="contact-input-text"
                                name="companySize"
                                value={formData.companySize}
                                onChange={handleInputChange}
                            >
                                <option value="">{translations?.message?.selectOne || "Select one"}</option>
                                {company_sizes.map((company, index) => (
                                    <option key={index} value={company.id}>
                                        {company.name}
                                    </option>
                                ))}
                            </select>
                            {formErrors.companySize && <span className="register-error">{formErrors.companySize}</span>}
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="contact-input">
                            <p className="contact-input-title">
                                {translations?.message?.jurisdiction || "Jurisdiction"} <span>*</span>
                            </p>
                            <select
                                className="contact-input-text"
                                name="jurisdictions"
                                value={formData.jurisdictions}
                                onChange={handleInputChange}
                            >
                                <option value="">{translations?.message?.selectOne || "Select one"}</option>
                                {jurisdictions.map((jurisdiction) => (
                                    <option key={jurisdiction.id} value={jurisdiction.id}>
                                        {jurisdiction.name}
                                    </option>
                                ))}
                            </select>
                            {formErrors.jurisdictions && <span className="register-error">{formErrors.jurisdictions}</span>}
                        </div>
                    </Col>
                    <Col md={12}>
                        <div className="contact-input">
                            <p className="contact-input-title">
                                {translations?.message?.note || "Note"}
                            </p>
                            <textarea
                                rows={"5"}
                                className="contact-input-text"
                                name="note"
                                value={formData.note}
                                onChange={handleInputChange}
                            ></textarea>
                        </div>
                    </Col>
                    <Col md={12}>
                        <div className="contact-input">
                            <input
                                type="submit"
                                value={isSubmitting ? translations?.message?.registering ? translations.message.registering + "..." : "Registering..." : translations?.message?.register || "Register"}
                                className="contact-submit"
                                disabled={isSubmitting}
                            />
                        </div>
                    </Col>
                </Row>
            </form>
        </div>
    );
};

export default RegisterForm;
