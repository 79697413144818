import React, { useEffect, useState } from 'react';
import './Results.css';
import axios from 'axios';
import { Reports } from '../../index';
import Cookies from 'js-cookie';
import Skeleton from 'react-loading-skeleton';
import { Paginations, } from "../../../../Components";
import { toast } from "react-toastify";

import SubModal from '@mui/material/Modal';
import Box from '@mui/material/Box';


// Debounce function
const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);


  return debouncedValue;
};

const Results = ({ url, dashboardData, selectedFilters, searchQuery, selectedDate, clearedDate, isAddNew, setIsAddNew, translations }) => {
  const reportSection = dashboardData?.find(item => item.section === "Reports List");

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [data, setData] = useState([]);
  let pageNumber = 0;
  const [loading, setLoading] = useState(true);
  const debouncedSearchQuery = useDebounce(searchQuery, 500); // 500ms debounce delay

  const [subPopup, setSubPopup] = useState(false);
     
  const [messagePopup, setMessagePopup] = useState("")
  const handleCloseSubPopup = () => {
      setSubPopup(false);
  };

  useEffect(() => {
    setLoading(true);
    if (reportSection?.data && reportSection?.data?.content?.reports?.objects) {
      setLoading(false);
    }
  }, [reportSection])


  useEffect(() => {

    if ((selectedFilters != {} && selectedFilters != undefined && Object.keys(selectedFilters).length > 0) || (selectedDate && (selectedDate.startDate !== null || selectedDate.endDate !== null)) || (clearedDate && clearedDate === true) || (isAddNew && isAddNew === true)) {
      fetchData();
    }
    else {
/*
if ((!selectedFilters || Object.keys(selectedFilters).length === 0))
*/  

      setCurrentPage(reportSection?.data?.content?.reports?.pagination?.metadata?.current_page ?? 1);
      setTotalPages(reportSection?.data?.content?.reports?.pagination?.metadata?.total_pages ?? 1);
      setData(reportSection?.data?.content?.reports?.objects ?? []);
    }
    if (isAddNew== true){
      setIsAddNew(false);
    }

    // else if ((selectedFilters != {} && selectedFilters != undefined && Object.keys(selectedFilters).length > 0) || (selectedDate && (selectedDate.startDate !== null || selectedDate.endDate !== null)) || (clearedDate && clearedDate === true )){
    //   fetchData();
    // }
  }, [selectedFilters, selectedDate, clearedDate, reportSection, isAddNew]);




  const fetchData = async () => {
    setLoading(true);
    try {
      const filterParams = Object.keys(selectedFilters)
        .filter((type) => selectedFilters[type].length > 0)
        .map((type) => {
          if (Array.isArray(selectedFilters[type])) {
            return `${type}=${encodeURIComponent(
              selectedFilters[type].join(",")
            )}`;
          } else {
            return `${type}=${encodeURIComponent(selectedFilters[type])}`;
          }
        })
        .join("&");

      const apiKey = Cookies.get("api_key");
      const apiSecret = Cookies.get("api_secret");
      let apiUrl = `${url}/api/method/projectx.projectx.doctype.law_report.law_report.law_report?start=${pageNumber}&limit=20${searchQuery ? `&search=${encodeURIComponent(searchQuery)}` : ""
        }`;
      if (filterParams) {
        apiUrl += `&${filterParams}`;
      }
      if (selectedDate && selectedDate.startDate && selectedDate.endDate) {
        apiUrl += `&created_at_start=${selectedDate.startDate}&created_at_end=${selectedDate.endDate}`;
      }
      // if (searchText) {
      //   apiUrl += `&search=${searchText}`
      // }
      const response = await axios.get(apiUrl, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${apiKey}:${apiSecret}`,
        },
      });

      if (response.data.status_code !== 200) {
        toast.error(translations?.message?.failedFetchData ? translations.message.failedFetchData :"Failed to fetch data. Please try again..");
      }

      if (response?.data?.status_code === 202) {
        setSubPopup(true);
      }
      const result = response.data.result;
      setCurrentPage(result.pagination.metadata.current_page);
      setTotalPages(result.pagination.metadata.total_pages);
      setData(result.objects);
    } catch (error) {
      if (error?.response?.data?.status_code === 202) {
        setSubPopup(true);
        setMessagePopup(error?.response?.data?.message)
      }
      toast.error(translations?.message?.failedFetchData ? translations.message.failedFetchData :"Failed to fetch data. Please try again..");
      console.error(translations?.message?.failedFetchData ? translations.message.failedFetchData :"Error fetching data:", error);
    }
    setLoading(false);
  };

  const onPageChange = (pageNumb) => {
    pageNumber = (pageNumb - 1) * 20;
    fetchData();
  };

  useEffect(() => {
    if (debouncedSearchQuery.length > 0) {
      fetchData();
    }
  }, [debouncedSearchQuery]);

  useEffect(() => {
    if (searchQuery === "" && debouncedSearchQuery.length > 0) {
      fetchData();
    }
  }, [searchQuery]);

  return (
    <div className="results">

      {loading ? (
        <>
          <div className="reminder-skelton">
            <div className="first-reminder-skelton">
              <Skeleton width={"100%"} height={18} />
              <Skeleton width={"100%"} height={13} />
            </div>
            <div className="first-reminder-skelton">
              <Skeleton width={"100%"} height={15} />
              <Skeleton width={"90%"} height={13} />
              <Skeleton width={"103%"} height={15} />
              <Skeleton width={"90%"} height={13} />
            </div>
          </div>
          <br />
          <div className="reminder-skelton">
            <div className="first-reminder-skelton">
              <Skeleton width={"100%"} height={18} />
              <Skeleton width={"100%"} height={13} />
            </div>
            <div className="first-reminder-skelton">
              <Skeleton width={"100%"} height={15} />
              <Skeleton width={"95%"} height={13} />
              <Skeleton width={"103%"} height={15} />
              <Skeleton width={"90%"} height={13} />
            </div>
          </div>
          <br />
          <div className="reminder-skelton">
            <div className="first-reminder-skelton">
              <Skeleton width={"100%"} height={18} />
              <Skeleton width={"100%"} height={13} />
            </div>
            <div className="first-reminder-skelton">
              <Skeleton width={"100%"} height={15} />
              <Skeleton width={"95%"} height={13} />
              <Skeleton width={"103%"} height={15} />
              <Skeleton width={"90%"} height={13} />
            </div>
          </div>
        </>
      ) :
        (
          <>
            {data.length == 0 ?
              <div class="not-found">{translations?.message?.noReports ? translations.message.noReports:"There are no reports."}</div> :
              (<>
                {data.map((item, index) => (
                  <Reports
                    name={item.name}
                    titleLink={`/dashboard/reports/${encodeURIComponent(item.name)}`}
                    title={item.report_name}
                    para={item.report_objects?.objects ?? []}
                    url={url}
                    onSuccess={() => fetchData()}
                    topic={item.topic}
                    note={item.note}
                    filters={item.filters}
                    date= {item.creation}
                    translations={translations}
                  />
                ))}
                <div className="d-flex justify-content-center mt-5">
                  <Paginations
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={onPageChange}
                  />
                </div>
              </>
              )
            }
          </>
        )}
          <SubModal
                open={subPopup}
                onClose={handleCloseSubPopup}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
                >
                <Box className="subpopup-container">
                    <p className='subpopup-text'>
                    {
                        messagePopup ? messagePopup : "your subscription has expired"
                    }
                    </p>
                    <a className='subpopup-link' href='/pricing'>Go To Pricing</a>
                </Box>
          </SubModal>
    </div>
  );
};

export default Results;