import React, { useState, useEffect } from 'react';
import "./ReportsPage.css"
import { Title,AddReport, DatePickers, Text } from "../../Components"
import titleImg from "../../assets/images/svg/reports.svg"
import { Results } from '../../Features/ReportsPage'
import Modal from 'react-modal';

const ReportsPage = ({ url, dashboardData, selectedFilters,setSelectedFilters, searchQuery ,setSearchQuery,callChangeReportFilters, reportObject, translations, language}) => {

    const reportSection = dashboardData?.find(item => item.section === "Reports List");
    const [isClear, setIsClear] = useState(false);
    const [isAddNew, setIsAddNew] = useState(false);

    const handleResetDate = () => {
        setSelectedDate(null); // Reset the selected date
        setIsClear(true);
      };
    
      const [selectedDate, setSelectedDate] = useState({ startDate: null, endDate: null });
    
      const handleDateChange = (date) => {
    
          if (!date || !date.startDate || !date.endDate) {
              // Handle the case where date or startDate or endDate is null
              return;
       }
        
       const formatDate = (inputDate) => { 
        if (!inputDate) return null;
        const year = inputDate.getFullYear();
        const month = (inputDate.getMonth() + 1).toString().padStart(2, "0");
        const day = inputDate.getDate().toString().padStart(2, "0");
        const hours = inputDate.getHours().toString().padStart(2, "0");
        const minutes = inputDate.getMinutes().toString().padStart(2, "0");
        const seconds = inputDate.getSeconds().toString().padStart(2, "0");
  
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      };
  
      // Set time to midnight for the start date and format it
      const startDateTime =
        date && date.startDate
          ? formatDate(new Date(date.startDate.setHours(0, 0, 0, 0)))
          : null;
      // Set time to midnight for the end date and format it
      const endDateTime =
        date && date.endDate
          ? formatDate(new Date(date.endDate.setHours(23, 59, 59, 0)))
          : null;
      setSelectedDate({
        startDate: startDateTime,
        endDate: endDateTime,
      });
      
    };
        
        
        const [showPopup, setShowPopup] = useState(false);

        const togglePopup = () => {
            setShowPopup(!showPopup);
          };
      
      useEffect(() => {
        }, [selectedDate]);

    return (
        <div className='innerpages-container'>
            <Title
                title={translations?.message?.reports ? translations.message.reports : "Reports"} 
                showInfo={false}
                icon={titleImg}
                titleBtn={true}
                onDateChange={handleDateChange}
                onReset={handleResetDate}
                url={url}
                dashboardData={dashboardData}
                selectedFilters={selectedFilters}
                setSelectedFilters={setSelectedFilters}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                reportObject={reportObject}
                callChangeReportFilters={callChangeReportFilters}
                translations={translations}
                language={language}
            />
            <p className='innerpages-para'>
                {reportSection?.data?.page_description ?? ""}
            </p>
            <div className='setting-report'>
              
            <div className='amendments-info'>
                <div className='hide-date-responsive'>
                    <Text para={translations?.message?.filterByDateRange ? translations.message.filterByDateRange : "Filter by data range:"} />
                    <div className='mt-2'>
                        <DatePickers 
                        inputType={"datetime"} 
                        onDateChange={handleDateChange}
                        onReset={handleResetDate}
                        translations={translations}
                        language={language}
                        />
                    </div>
                </div>
                
            </div>
            <div style={{textAlign: "end", width:"100%"}}>

            <button className='add-report-btn' onClick={togglePopup} >
            {translations?.message?.addNewReport ? translations.message.addNewReport : "Add a New Report"} 
            </button>
            </div>

            </div>
            <Modal
                isOpen={showPopup}
                onRequestClose={togglePopup}
                contentLabel="Select Filter Popup"
              >
                <AddReport
                  url={url}
                  cancelBtn={togglePopup}
                  showAddNew={true}
                  is_empty={true}
                  onRefresh={setIsAddNew}
                  translations={translations}
                />
              </Modal>
           
            <Results
                url={url} dashboardData={dashboardData} selectedFilters={selectedFilters}
                searchQuery={searchQuery}
                selectedDate={selectedDate}
                clearedDate={isClear}
                isAddNew={isAddNew}
                setIsAddNew={setIsAddNew}
                translations={translations}
            />
        </div>
    )
}

export default ReportsPage
