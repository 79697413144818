import React, { useState } from 'react';
import "./Note.css";
import { AddReport, Text } from "../../Components";
import { Row, Col } from "react-bootstrap";
import Modal from "react-modal"
import addbtn from "../../assets/images/svg/addbtn.svg";
import { Link } from 'react-router-dom'; 

const Note = ({url, date, textDate, para, title, title2, titleLink, textTitle, showButton, showNote, notePara, docName, translations }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isLongNote, setIsLongNote] = useState(false);
  const [showReport, setShowReport] = useState(false);


  const toggleReport = () => {
    setShowReport(!showReport);
  }

  const MAX_LENGTH = 100; // Adjust the length threshold as needed

  // Check if the notePara is long and set the state accordingly
  React.useEffect(() => {
    const div = document.createElement('div');
    div.innerHTML = notePara;
    setIsLongNote(div.textContent.length > MAX_LENGTH);
  }, [notePara]);

  const handleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  const getTruncatedHtml = (html, maxLength) => {
    const div = document.createElement('div');
    div.innerHTML = html;
    let truncated = div.textContent.slice(0, maxLength) + '...';
    return truncated;
  };

  return (
    <Row className="note-content">
      <Col md={3} lg={4}>
        <div className="note-info">
          <h1 className='note-date'>{date}</h1>
          <Text para={textDate} />
          {showButton && (
            <button className='note-btn' onClick={toggleReport}>
              <img className='note-btn-icon' src={addbtn} alt="Add" />
             {translations?.message?.addToReport ? translations.message.addToReport : "Add to Report"}
              </button>
          )} 
        </div>
      </Col>
      <Col md={7} lg={8} className='note-content-container'>
        <a href={`${titleLink}`} className='note-title'>{title}</a>
        <Text para={textTitle} />
        <h1 className='note-title'>{title2}</h1>
        <Text para={para} />
        {showNote && (
          <p className='note-para'>
            <span>{translations?.message?.note ? translations.message.note+ ":" :'Note:'}</span> 
            <span dangerouslySetInnerHTML={{ __html: isLongNote && !isExpanded ? getTruncatedHtml(notePara, MAX_LENGTH) : notePara }} />
            {isLongNote && (
              <a onClick={handleReadMore} style={{ cursor: 'pointer' }}>
                {isExpanded ? translations?.message?.showLess ? translations.message.showLess :' Show Less' :  translations?.message?.readMore ? translations.message.readMore:' Read More'}
              </a>
            )}
          </p>
        )}
      </Col>
      <Modal
            isOpen={showReport}
            onRequestClose={toggleReport}
            contentLabel='Select Filter Popup'
        >            
            <AddReport cancelBtn={toggleReport} url={url}  regulation={docName??title2} translations={translations}/> 
      </Modal>
    </Row>
  );
};

export default Note;