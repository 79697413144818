import React, { useState } from 'react';
import './Reports.css';
import axios from 'axios';
import Cookies from 'js-cookie';
import { Note, DeletBtn, ConfirmDelete } from '../../../../Components';
import Modal from 'react-modal';

import SubModal from '@mui/material/Modal';
import Box from '@mui/material/Box';

const Reports = ({
  date,
  textDate,
  title,
  textTitle,
  showButton,
  showNote,
  notePara,
  name,
  url,
  onDeleteNote,
  docName,
  titleLink,
  translations
}) => {

  const [subPopup, setSubPopup] = useState(false);
     
  const handleCloseSubPopup = () => {
      setSubPopup(false);
  };


  const [showPopup, setShowPopup] = useState(false);
  const [messagePopup, setMessagePopup] = useState("")

  const handleDelete = () => {
    setShowPopup(true);
  };

  const confirmDelete = () => {
    const apiUrl = `${url}/api/method/projectx.projectx.doctype.document_note.document_note.document_note?name=${name}`;
    const apiKey = Cookies.get('api_key');
    const apiSecret = Cookies.get('api_secret');

    axios
      .delete(apiUrl, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `token ${apiKey}:${apiSecret}`,
        },
      })
      .then((response) => {
        if (response?.data?.status_code === 202) {
          setSubPopup(true);
        }
        onDeleteNote();
      })
      .catch((error) => {
        if (error?.response?.data?.status_code === 202) {
          setSubPopup(true);
          setMessagePopup(error?.response?.data?.message)
        }
      });

    setShowPopup(false);
  };

  const cancelDelete = () => {
    setShowPopup(false);
  };

  return (
    <div className="notes-content">  
      <Note url={url} date={date} textDate={textDate} title={title} textTitle={textTitle} showButton={showButton} showNote={showNote} notePara={notePara} docName={docName} titleLink={titleLink} translations={translations} />

      <div className="notes-btn" onClick={handleDelete}>
        <DeletBtn name= {translations?.message?.deleteNote ? translations.message.deleteNote : "Delete Note"}  />
      </div>

      <Modal isOpen={showPopup} onRequestClose={cancelDelete}>
        <ConfirmDelete
          para= {translations?.message?.areSureDelete ? translations.message.areSureDelete : "Are you sure you want to delete this?"}
          cancleDelete={cancelDelete}
          confirmDelete={confirmDelete}
          deletePara={translations?.message?.yesDelete ? translations.message.yesDelete : "Yes, Delete"}
          translations={translations}
       />
      </Modal>
      <SubModal
            open={subPopup}
            onClose={handleCloseSubPopup}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
            >
              <Box className="subpopup-container">
                    <p className='subpopup-text'>
                    {
                        messagePopup ? messagePopup : "your subscription has expired"
                    }
                    </p>
                    <a className='subpopup-link' href='/pricing'>Go To Pricing</a>
                </Box>
        </SubModal>
    </div>
  );
};

export default Reports;