import React, { useState } from 'react';
import './NotePopUp.css';
import axios from 'axios';
import Cookies from 'js-cookie';
import global from '../../assets/images/svg/global.svg';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Box from '@mui/material/Box';
import SubModal from '@mui/material/Modal';

const NotePopUp = ({ count, isOpen, onCancel, url, docName, docType, onRefresh, translations  }) => {
  const [noteText, setNoteText] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isSaving, setIsSaving] = useState(false); // State to track saving state


  const [subPopup, setSubPopup] = useState(false);
  const [messagePopup, setMessagePopup] = useState("")
  
  const handleClose = () => {
      setSubPopup(false);
  };

  const handleCancel = () => {
    onCancel();
  };

  const handleSaveNote = () => {
    if (noteText.trim() === '') {
      setErrorMessage(translations?.message?.pleaseAddNote ? translations.message.pleaseAddNote :'Please Add a Note');
      return;
    }
    
    // const pathSegments = window.location.pathname.split('/');
    // const lastSegment = pathSegments[pathSegments.length - 1];    
    // let noteDoctype;
    // if (["regulation","amendments","search","dashboard"].includes(lastSegment) || window.location.pathname.includes('/dashboard/reports/') || window.location.pathname.includes('/dashboard/regulation/')) {
    //   noteDoctype = `${docType}`;
    // } 

    const data = {
      document_type: "Legislation" ,
      document_name: docName,
      details: noteText,
    };
  
    const apiUrl = `${url}/api/method/projectx.projectx.doctype.document_note.document_note.document_note`;
    const apiKey = Cookies.get('api_key');
    const apiSecret = Cookies.get('api_secret');

    // Disable the button while saving
    setIsSaving(true);

    axios
      .post(apiUrl, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `token ${apiKey}:${apiSecret}`,
        },
      })
      .then((response) => {
        if (response?.data?.status_code === 202) {
          setSubPopup(true);
        }
        onCancel(); // Close the component after saving
        toast.success(translations?.message?.noteAddedSuccessfuly ? translations.message.noteAddedSuccessfuly :'Note saved successfully.'); // Show success toast
        setNoteText(''); // Clear the textarea
        if(onRefresh){
          onRefresh();
        }
        
      })
      .catch((error) => {
        if (error?.response?.data?.status_code === 202) {
          setSubPopup(true);
          setMessagePopup(error?.response?.data?.message)
        }
        console.error(error);
        toast.error(translations?.message?.failedSaveNote ? translations.message.failedSaveNote :'Failed to save the note.'); // Show error toast
      })
      .finally(() => {
        setIsSaving(false); // Enable the button after API call completes
      });
  };

  if (!isOpen) {
    return null; // Return null to hide the component
  }

  return (
    <>
      <div className="note-pop-up">
        <div className="note-header">
          <div className="global-notes">
            <img className="note-header-image" src={global} alt="Global Note" />
            <h1 className="note-header-title">
             {translations?.message?.addNote ? translations.message.addNote : "Add Note"}
              </h1>
            {/* <h1 className="note-header-count">{count}</h1> */}
          </div>
          <Link  reloadDocument to="/dashboard/notes" className="see-all-notes">
            <h1 className="see-all-notes-title">
            {translations?.message?.seeAllNotes ? translations.message.seeAllNotes : "See All Notes"}
              </h1>
          </Link>
        </div>
        <div className="note-text">
          <textarea
            className="note-text-input"
            placeholder={translations?.message?.addYourNote ? translations.message.addYourNote : "Add your note"}
            rows={4}
            value={noteText}
            onChange={(e) => setNoteText(e.target.value)}
          />
        </div>
        {errorMessage && (
          <div className="popup-error">
            <p>{errorMessage}</p>
          </div>
        )}
        <div className="note-footer">
          {/* <div>
            <button className="note-delete-btn">Delete</button>
          </div> */}
          <div>
            <button className="note-cancel-btn" onClick={handleCancel}>
            {translations?.message?.cancel ? translations.message.cancel : "Cancel"}
            </button>
            <button className="note-save-btn" onClick={handleSaveNote} disabled={isSaving}>
              {isSaving ? translations?.message?.saving ? translations.message.saving +"...": "Saving..." : translations?.message?.save ? translations.message.save : "Save"}
            </button>
          </div>
        </div>
        <SubModal
              open={subPopup}
              onClose={handleClose}
              aria-labelledby="child-modal-title"
              aria-describedby="child-modal-description"
              >
              <Box className="subpopup-container">
                    <p className='subpopup-text'>
                    {
                        messagePopup ? messagePopup : "your subscription has expired"
                    }
                    </p>
                    <a className='subpopup-link' href='/pricing'>Go To Pricing</a>
                </Box>
          </SubModal>
      </div>
      
    </>
  );
};

export default NotePopUp;
