import React, { useState, useRef } from 'react';
import "./AddEmployeeModal.css";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/system';
import Cookies from 'js-cookie';
import Camera from "../../../../assets/images/svg/camera-icon.svg";
import { toast } from "react-toastify";
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import { SpinnerLoading } from "../../../../Components"; // Ensure this component is imported correctly
import svgImage from '../../../../assets/images/svg/userInput.svg'; // Import your SVG image

import SubModal from '@mui/material/Modal';




const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const CustomTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#ccc', // Default border color
    },
    '&:hover fieldset': {
      borderColor: 'var(--main-color)',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'var(--main-color)', // Border color when focused
    },
  },
  '& .MuiInputLabel-root': {
    marginTop: "-5px",
    fontSize: '14px',
    fontFamily: "Poppins400"
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: "var(--main-color)"
  },
});

export default function AddEmployeeModal({ url, open, handleClose, handleSave,fetchData, translations }) {
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // Loading state

  const [employeeData, setEmployeeData] = useState({
    firstName: '',
    lastName: '',
    province: '',
    userImage: '',
    email: ''
  });
  const [errors, setErrors] = useState({});
  const [file, setFile] = useState(null);
  const [fileBase64, setFileBase64] = useState('');
  // const [imagePreviewUrl, setImagePreviewUrl] = useState(require("../../../../assets/images/svg/user.svg")); // Default image
  const [imagePreviewUrl, setImagePreviewUrl] = useState(svgImage); // Set the default image to the SVG image

  const [subPopup, setSubPopup] = useState(false);
  const [messagePopup, setMessagePopup] = useState("")   

  const handleCloseSubPopup = () => {
      setSubPopup(false);
  };


  // const [imagePreviewUrl, setImagePreviewUrl] = useState(require("../../../../assets/images/dashboard/Mask group.png")); // Default image
  const fileInputRef = useRef(null);

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Basic validation for required fields
    if (value.trim() === '' && (name === 'firstName' || name === 'lastName')) {
      setErrors({ ...errors, [name]: `${name.charAt(0).toUpperCase() + name.slice(1)} ${translations?.message?.isRequired ? translations.message.isRequired : "is required" } `});
    } else {
      if ((name === 'firstName' || name === 'lastName') && !/^[a-zA-Z0-9!@#$%^&*() ]+$/.test(value)) {
        setErrors({ ...errors, [name]: `${name.charAt(0).toUpperCase() + name.slice(1)}  ${translations?.message?.mustContainOnlyLettersAndSpaces ? translations.message.mustContainOnlyLettersAndSpaces : "must contain only letters and spaces" }` });
      } else {
        setErrors({ ...errors, [name]: '' });
      }
    }

    if (name === 'email') {
      const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
      setErrors({ ...errors, [name]: isValidEmail ? '' : translations?.message?.enterValidEmail  ? translations.message.enterValidEmail : 'Please enter a valid email address'  });
    }

    setEmployeeData({ ...employeeData, [name]: value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setFileBase64(reader.result);
        setImagePreviewUrl(reader.result); // Update the image preview
      };
      reader.readAsDataURL(file);
    }
  };

  const handleFileClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleSubmit = async () => {
    if (loading) {
      return; 
    }
    setLoading(true);
    const dataToSubmit = {
      first_name: employeeData.firstName,
      last_name: employeeData.lastName,
      email: employeeData.email,
      role: "Company Employee",
      province: employeeData.province,
      user_image: fileBase64 ? {
        filename: file.name,
        data: fileBase64,
      } : null,
    };
    var response;
    try {
      const apiKey = Cookies.get('api_key');
      const apiSecret = Cookies.get('api_secret');
      let apiUrl = `${url}/api/method/erpnext.selling.doctype.customer.customer.employee`;
       response = await axios.post(apiUrl,dataToSubmit, {
        headers: {
            "Content-Type": "application/json",
            Authorization: `token ${apiKey}:${apiSecret}`,
        },
        // body: JSON.stringify(dataToSubmit),
    });
      // const response = (`${url}/api/method/erpnext.selling.doctype.customer.customer.employee`, {
      //   method: 'POST',
      //   headers: {
      //     'Content-Type': 'application/json',
      //     Authorization: `token ${apiKey}:${apiSecret}`,
      //   },
      //   body: JSON.stringify(dataToSubmit),
      // });
      // console.log("response",response);


      if (response.data.status_code == 200) {
        handleSave(employeeData);
        toast.success(translations?.message?.employeeAddedSuccessfuly ? translations.message.employeeAddedSuccessfuly :"Employee Added successfully.");
        handleClose(); // Close the modal after successful save
        setEmployeeData({
          firstName: '',
          lastName: '',
          province: '',
          userImage: '',
          email: ''
        });
        fetchData(0,10);
      }
      
       else {
        console.error(translations?.message?.employeeAddedSuccessfuly ? translations.message.employeeAddedSuccessfuly :'Failed to save employee data');
        toast.error(translations?.message?.employeeNotAdded ? translations.message.employeeNotAdded :"Employee Not Added.");
       
      }

      if (response?.data?.status_code === 202) {
        setSubPopup(true);
      }
    } 
    catch (error) {
      if (error?.response?.data?.status_code === 202) {
        setSubPopup(true);
        setMessagePopup(error?.response?.data?.message)
      }
      if(error.response.data.status_code == 409){
        toast.error(translations?.message?.exceededTheLimitForAddingEmployees ? translations.message.exceededTheLimitForAddingEmployees :"You have exceeded the limit for adding employees.")
      }
      handleClose();
    }
    finally {
      setLoading(false);
      
    }
  };

  return (
    <>
      <SubModal
            open={subPopup}
            onClose={handleCloseSubPopup}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
            >
              <Box className="subpopup-container">
                    <p className='subpopup-text'>
                    {
                        messagePopup ? messagePopup : "your subscription has expired"
                    }
                    </p>
                    <a className='subpopup-link' href='/pricing'>Go To Pricing</a>
                </Box>
        </SubModal>
        <Modal
      className='add-employee-modal'
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
      {loading ? (
      <div className="profile-page-loading-container">
        <div className="profile-page-loading">
          <SpinnerLoading />
        </div>
      </div>
    ) : (
      <>

        <Typography id="modal-modal-title" className='add-employee-title'>
        {translations?.message?.addEmployee ? translations.message.addEmployee : "Add New Employee"}
         
        </Typography>
        <div
          className="col-12 profile-photo-div-parent"
          onClick={handleFileClick}
          onDrop={(event) => event.preventDefault()}
          onDragOver={(event) => event.preventDefault()}
        >
          <div className="profile-photo-div">
            <label className="custom-file-upload">
              <img src={imagePreviewUrl} className="profile-img" alt="Profile" />
              <img src={Camera} className="c1" alt="Camera Icon" />
            </label>
            <input
              id="select_files"
              type="file"
              ref={fileInputRef}
              accept="image/x-png,image/png,image/jpeg,image/jpg,image/svg+xml"
              onChange={handleFileChange}
              style={{ display: "none" }}
            />
          </div>
        </div>
        <CustomTextField
          fullWidth
          margin="normal"
          name="firstName"
          label={translations?.message?.firstName ? translations.message.firstName : "First Name"}
          variant="outlined"
          value={employeeData.firstName}
          onChange={handleChange}
          error={!!errors.firstName}
          helperText={errors.firstName}
        />
        <CustomTextField
          fullWidth
          margin="normal"
          name="lastName"
          label={translations?.message?.lastName ? translations.message.lastName : "Last Name"}
          variant="outlined"
          value={employeeData.lastName}
          onChange={handleChange}
          error={!!errors.lastName}
          helperText={errors.lastName}
        />
        <CustomTextField
          fullWidth
          margin="normal"
          name="email"
          label={translations?.message?.email ? translations.message.email : "Email"}
          variant="outlined"
          value={employeeData.email}
          onChange={handleChange}
          error={!!errors.email}
          helperText={errors.email}
        />
        {/* <Typography id="modal-province-title" className='second-add-employee-title'>
          Provinces
        </Typography>
        <div className='add-report-checks'>
          <div className='add-report-content'>
            <input type='checkbox' className='add-report-checkbox' /><label className='add-report-label'>Manitoba.</label>
          </div>
          <div className='add-report-content'>
            <input type='checkbox' className='add-report-checkbox' /><label className='add-report-label'>Northwest Territories.</label>
          </div>
          <div className='add-report-content'>
            <input type='checkbox' className='add-report-checkbox' /><label className='add-report-label'>Nova Scotia.</label>
          </div>
          <div className='add-report-content'>
            <input type='checkbox' className='add-report-checkbox' /><label className='add-report-label'>Manitoba.</label>
          </div>
          <div className='add-report-content'>
            <input type='checkbox' className='add-report-checkbox' /><label className='add-report-label'>Northwest Territories.</label>
          </div>
          <div className='add-report-content'>
            <input type='checkbox' className='add-report-checkbox' /><label className='add-report-label'>Nova Scotia.</label>
          </div>
        </div> */}
        <div className='add-employee-btns'>
          <Button className='add-employee-cancle' onClick={handleClose} disabled={loading}>
            {translations?.message?.cancel ? translations.message.cancel : "Cancel"}
            </Button>
          <Button className='add-employee-save' onClick={handleSubmit} disabled={loading}>{loading ? <CircularProgress size={24} />  : translations?.message?.save ? translations.message.save : "Save"}</Button>
        </div>
        </>
    )}
    
      </Box>
    </Modal>
    </>
    
  );
}
