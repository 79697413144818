import React from "react";
import "./AboutTeam.css";
import { Container } from "react-bootstrap";
import { TeamCards } from "../../index";
import { ContactUs } from "../../../../Components";

const AboutTeam = ({url,teamSection, contactSection, translations, language}) => {
  let title = "", description = "", team=[];
  if (teamSection) {
    ({
      title, description, team
    } = teamSection.data|| {});

  }
  return (
    <div>
      <Container className="about-team about-container">
        <h1
          data-aos="fade-down"
          data-aos-duration="1500"
          className="about-team-title"
        >
         {title}
        </h1>
        <p
          data-aos="fade-down"
          data-aos-duration="1800"
          className="about-team-para"
          dangerouslySetInnerHTML={{__html: description}}
        >
        </p>
        <TeamCards url={url} team= {team} translations={translations}/>
        <ContactUs url={url} contactSection={contactSection} translations={translations} language={language} />
      </Container>
    </div>
  );
};

export default AboutTeam;
