import React, { useState, useEffect, useRef } from 'react';
import "./Detail.css";
// import "../../../../assets/css/theme.css"
// import "../../../../assets/css/lawContent.css"
import axios from 'axios';
import Cookies from 'js-cookie';
import { AddBtn, Alert, ReminderPopUp, NotePopUp,AddReport } from '../../../../Components';
import message from "../../../../assets/images/svg/graymessage.svg";
import clock from "../../../../assets/images/svg/grayclock.svg";
import share from "../../../../assets/images/svg/share.svg";
import Modal from "react-modal";

import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { pdfjs } from 'pdfjs-dist';

// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

import * as pdfjsLib from 'pdfjs-dist/build/pdf';
import pdfWorker from 'pdfjs-dist/build/pdf.worker.entry';
pdfjsLib.GlobalWorkerOptions.workerSrc = pdfWorker;



const Detail = ({
    namebtn1,
    namebtn2, 
    namebtn3,
    imgbtn1,
    imgbtn2,
    imgbtn3,
    title,
    showSecondAlert,
    showFirstAlert,
    mainTitle,
    date,
    url,
    xmlFilName,
    pdfFileName,
    regulationName,
    amendmentNum,
    resourcesData,
    regulationType,
    legId,
    translations
}) => {
    const [showRegulationBtns, setShowRegulationBtns] = useState(false);
    const [isSmallScreen, setIsSmallScreen] = useState(false);
    const [noteText, setNoteText] = useState("");
    const [showAddNote, setShowAddNote] = useState(false);
    const [showAddReminder, setShowAddReminder] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const notePopUpRef = useRef(null);
    const reminderPopUpRef = useRef(null);
    const showBtnsRef = useRef(null);  // Ref for the `show-btns` container
    const dateTimePickerRef = useRef(null);
    const iframeRef = useRef(null);
    const [xmlFileData, setXmlFileData] = useState("")
    // Ref to track if XML data has already been loaded
    const isDataFetched = useRef(false);
    
    const xmlFileName = xmlFilName;
    useEffect(() => {
      
  
      if (xmlFileName && !isDataFetched.current) {
          isDataFetched.current = true; // Set ref to true to avoid duplicate calls
  
          const htmlFileName = xmlFileName.replace('.xml', '.html');
          const src = `https://front.polarisai.ca/files/${htmlFileName}`;
  
          axios.get(src)
              .then(response => {
                  setXmlFileData(response.data);
              })
              .catch(error => {
                  console.error("Error fetching XML file:", error);
                  isDataFetched.current = false; // Reset if there's an error
              });
      }
  }, [xmlFileName])

    const togglePopup = () => {
        setShowPopup(!showPopup);
      }

    

    // const handleSaveNote = () => {
    //     const data = {
    //         document_type: "Canlii Legislation",
    //         document_name: "qcs-cqlr-c-a-3",
    //         details: noteText
    //     };

    //     const apiUrl = `${url}/api/method/projectx.projectx.doctype.document_note.document_note.document_note`;
    //     const apiKey = Cookies.get('api_key');
    //     const apiSecret = Cookies.get('api_secret');

    //     axios
    //         .post(
    //             apiUrl,
    //             data,
    //             {
    //                 headers: {
    //                     "Content-Type": "application/json",
    //                     "Authorization": `token ${apiKey}:${apiSecret}`
    //                 },
    //             }
    //         )
    //         .then(response => {
    //             // console.log(response.data);
    //         })
    //         .catch(error => {
    //             console.error(error);
    //         });
    // };

    const handleAddNoteClick = () => {
        setShowAddNote(!showAddNote);
    };
    const handleAddReminderClick = () => {
        setShowAddReminder(!showAddReminder);
    };

    const handleClickOutside = (event) => {
        if (notePopUpRef.current && !notePopUpRef.current.contains(event.target)) {
            setShowAddNote(false);
        }
    };
    const handleClickOutSide = (event) => {
        if (reminderPopUpRef.current && !reminderPopUpRef.current.contains(event.target)) {
            setShowAddReminder(false);
        }
    };

    const handleClickOutsideReminder = (event) => {
        const isClickInsideReminderPopup = reminderPopUpRef.current && reminderPopUpRef.current.contains(event.target);
            const isClickInsideDatePicker = dateTimePickerRef.current && dateTimePickerRef.current.contains(event.target);
            const isClickInsideDatePickerPopper = document.querySelector('[role="dialog"]')?.contains(event.target);
            if (!isClickInsideReminderPopup && !isClickInsideDatePicker && !isClickInsideDatePickerPopper) {
          setShowAddReminder(false);
        }
      };

      useEffect(() => {
        window.addEventListener("mousedown", handleClickOutsideReminder);
        return () => {
          window.removeEventListener("mousedown", handleClickOutsideReminder);
        };
      }, []);


      const handleBtnsClickOutside = (event) => {
        if (
          showBtnsRef.current &&
          !showBtnsRef.current.contains(event.target)
        ) {
            setShowRegulationBtns(false);
        }
      };

    

    const handleShowBtnsClick = () => {
        if (isSmallScreen) {
            setShowRegulationBtns(!showRegulationBtns);
        }
    };

    useEffect(() => {
        window.addEventListener("mousedown", handleClickOutside);
        return () => {
            window.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        window.addEventListener("mousedown", handleBtnsClickOutside);
        return () => {
            window.removeEventListener("mousedown", handleBtnsClickOutside);
        };
    }, []);

    useEffect(() => {
    
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 1200);
        };

        window.addEventListener("resize", handleResize);
        handleResize(); // Check initial screen width on component mount

        return () => {
            window.removeEventListener("resize", handleResize);
        };
      
    }, []);

    useEffect(() => {
        const handleMessage = (event) => {
            if (event.data.type === 'setHeight' && iframeRef.current) {
                iframeRef.current.style.height = `${event.data.height}px`;
            }
        };

        window.addEventListener('message', handleMessage);

        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []);

    useEffect(() => {
        const iframe = iframeRef.current;
        const updateIframeHeight = () => {
          if (iframe && iframe.contentWindow) {
            const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
            if (iframeDocument.body) {
              iframe.style.height = iframeDocument.body.scrollHeight + 'px';
            }
          }
        };
    
        if (iframe) {
          iframe.addEventListener('load', updateIframeHeight);
          // Cleanup event listener on component unmount
          return () => iframe.removeEventListener('load', updateIframeHeight);
        }
      }, []);

    return (
      <div className="detail">
        <div className="detail-header">
          <div className="detail-side">
            <div className="position-relative">
              <button className="detail-side-btn" onClick={handleAddNoteClick}>
                <img src={message} />
              </button>
              <div
                ref={notePopUpRef}
                className={`NotePopUp ${showAddNote ? "active" : ""}`}
              >
                <NotePopUp
                  url={url}
                  count={"4"}
                  isOpen={showAddNote}
                  onCancel={handleAddNoteClick}
                  docName={legId}
                  translations={translations}
                />
              </div>
            </div>
            <div className="position-relative">
              <button
                className="detail-side-btn"
                onClick={handleAddReminderClick}
              >
                <img src={clock} />
              </button>
              <div
                ref={reminderPopUpRef}
                className={`ReminderPopUp ${showAddReminder ? "active" : ""}`}
              >
                <ReminderPopUp
                  url={url}
                  count={"4"}
                  isOpen={showAddReminder}
                  onCancel={handleAddReminderClick}
                  docName={legId}
                  dateTimePickerRef={dateTimePickerRef}
                  translations={translations}
                />
              </div>
            </div>

            {/* <button className='detail-side-btn'>
                        <img src={share} />
                    </button> */}
          </div>

          <div className="detail-header-content">
            <h1 className="detail-header-title">{mainTitle}</h1>
            <p className="detail-header-para">
              <div>{translations?.message?.updated ? translations.message.updated+ ":": "Updated:"} {date}</div>
              <div className="deail-header-version">
              {translations?.message?.version ? translations.message.version: "Version"} <span>2.0.1</span>
              </div>
            </p>
          </div>
          {isSmallScreen && (
            <div className="show-btns" onClick={handleShowBtnsClick}></div>
          )}
          {(isSmallScreen && showRegulationBtns) || !isSmallScreen ? (
            <div className="detail-btns" ref={showBtnsRef}>
              <div className="detail-btn disable-btn">
                <AddBtn name={namebtn1} svgImage={imgbtn1} />
              </div>
              <div className="detail-btn disable-btn">
                <AddBtn name={namebtn2} svgImage={imgbtn2} />
              </div>
              <div className="detail-btn" onClick={togglePopup}>
                <AddBtn name={namebtn3} img={imgbtn3} />
              </div>
            </div>
          ) : null}
          <Modal isOpen={showPopup} onRequestClose={togglePopup}>
            <AddReport
              cancelBtn={togglePopup}
              url={url}
              regulation={legId}
              translations={translations}
            />
          </Modal>
        </div>
        {showFirstAlert && (
          <Alert
            bg={"rgba(45, 176, 216, 0.14)"}
            border={"#2DB0D8"}
            showLink={false}
            showWarningImage={false}
            para={resourcesData.map((item, index) => (
              <>
                {`${item.XRefExternal} ${item.link}`}
                <br />
              </>
            ))}
            paraColor={"#2DB0D8"}
          />
        )}

        {showSecondAlert && (
          <Alert
            bg={"rgba(251, 188, 5, 0.14)"}
            border={"#FBBC04"}
            showLink={true}
            showWarningImage={true}
            para={translations?.message?.provisionAffectedBy ? translations.message.provisionAffectedBy: `This provision is affected by` + amendmentNum + translations?.message?.amendment ? translations.message.amendment : `amendment.`}
            paraColor={"#B78800"}
            linkPara={translations?.message?.viewDetails ? translations.message.viewDetails: "View Details"}
            linkColor={"#B78800"}
            linkHref={`/dashboard/amendments/${encodeURIComponent(
              regulationName
            )}`}
          />
        )}
        <div className="regulations-details-parent">
          {/* Conditionally render based on file extension */}
            {
                xmlFilName && (
                    xmlFileName.endsWith(".xml") ? (
                        <div className="regulations-details" dangerouslySetInnerHTML={{ __html: xmlFileData }}></div>
                    ) : xmlFileName.endsWith(".pdf") ? (
                        <Viewer
                        fileUrl={`https://front.polarisai.ca/files/${xmlFileName}`}
                        />
                    ) : (
                        <p>{translations?.message?.unsupportedFileType ? translations.message.unsupportedFileType: "Unsupported file type"}</p>
                    )
                )
            }
       

        </div>
      </div>
    );
};

export default Detail;
