import React from 'react'
import "./Updates.css"
import { Title, Text, DatePickers, ChartComponent } from '../../../../Components'

const Updates = ({ dashboardData, translations }) => {
    return (
        <div className='updates'>
            <Title
                title={translations?.message?.recentAmendments ? translations.message.recentAmendments : "Recent Amendments"}
                showInfo={true}
                titleBtn={false}
                tooltip={translations?.message?.recentAmendmentsTooltip ? translations.message.recentAmendmentsTooltip : `"Recent Amendments" provides information about the number of adjustments made each year using the chart.`}
                translations={translations}
            />
            <div className='mt-2'>
                <p className='innerpages-para'>
                    {translations?.message?.amendmentsChart ? translations.message.amendmentsChart : "This bar chart visualizes the number of amendments made each year, highlighting the year with the highest number of amendments and helping to identify trends and significant changes in activity."}
                </p>
            </div>

            <div className='chart-container'>
                <ChartComponent dashboardData={dashboardData} translations={translations}/>
            </div>
        </div>
    )
}

export default Updates
