import React from 'react'
import "./AboutPage.css"
import { ContactUs, Hero } from '../../Components'
import { Container, Row, Col } from "react-bootstrap"
import { About, AboutHero, Question } from "../../Features"
import { Lawyers } from "../../Features/Home"


const AboutPage = ({url, data, translations, language}) => {
    const aboutSection = data?.find(item => item.section === "About Us Section");
    const frequentlySection = data?.find(item => item.section === "Frequently Asked Questions Section");
    const lawyersSection = data?.find(item => item.section === "Lawyers Section");
    const contactSection = data?.find(item => item.section === "Contact Us Section");

    return (
        <div className='about-page'>

                <Hero 
                    mainTitle={translations?.message?.home ? translations.message.home + " / " :"Home" + " / "}
                    title={translations?.message?.aboutUs ? translations.message.aboutUs :"About us"}
                    para={translations?.message?.aboutUs ? translations.message.aboutUs :"About us"}
                />
                <Container>
                    {aboutSection &&  <About url={url} aboutSection={aboutSection} />}
                    {frequentlySection && <Question url={url} frequentlySection={frequentlySection}/> }
                    {lawyersSection &&  <Lawyers url={url} lawyersSection={lawyersSection} />}
                    {contactSection &&  <ContactUs url={url} contactSection={contactSection} translations={translations} language={language} />}

                </Container>
            
        </div>
        
    )
}

export default AboutPage