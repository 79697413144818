import React from 'react';
import "./Province.css";
import Checkbox from '@mui/material/Checkbox';

const Province = ({ label }) => {
    const checkboxProps = { inputProps: { 'aria-label': label } };

    return (
        <div className='province-container'>
            <Checkbox {...checkboxProps}
                sx={{
                    '& .MuiSvgIcon-root': {
                        fontSize: '15px', // Adjust the size of the checkbox
                        width: '22px',    // Set custom width
                        height: '22px',   // Set custom height
                    },
                    color: '#fff',
               
                    '&.Mui-checked': {
                        color: '#2DB0D8',
                    },
                }} 
            />
            {label && <span className='province-container-label'>{label}</span>}
        </div>
    );
};

export default Province;
