import React, { useState, useEffect } from 'react';
import './Reports.css';
import axios from 'axios';
import Cookies from 'js-cookie';
import { Note, RemoveBtn, RemainingDays, ConfirmDelete } from '../../../../Components';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from "react-modal"

import SubModal from '@mui/material/Modal';
import Box from '@mui/material/Box';


const Reports = ({ url, name, date, textDate, title, textTitle, title2, para, disable, showButton, removeBtn, days, hours, onDeleteReminder, titleLink, translations }) => {

  const [showPopup, setShowPopup] = useState(false);
  const [isDisabled, setIsDisabled] = useState(disable);
  const [buttonText, setButtonText] = useState(removeBtn);

  const [subPopup, setSubPopup] = useState(false);
     
  const [messagePopup, setMessagePopup] = useState("")
  const handleCloseSubPopup = () => {
      setSubPopup(false);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const timeString = date.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });

    return timeString !== "00:00"
      ? date.toLocaleString(undefined, {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      })
      : date.toLocaleDateString(undefined, {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
  };

  const handleDelete = () => {
    if (isDisabled === 0) {
      setButtonText(translations?.message?.deactivateReminder ? translations.message.deactivateReminder : "Deactivate Reminder");
    } else {
      setButtonText(translations?.message?.activateReminder ? translations.message.activateReminder : "Activate Reminder");
    }

    if (removeBtn === (translations?.message?.expired ? translations.message.expired : "Expired")) {
      // Prevent further action if the reminder is expired
      return;
    }
    setShowPopup(true);
  };

  const confirmDelete = () => {
    if (new Date(date) < new Date()) {
      console.error("Error: Reminder cannot be created in the past.");
      setButtonText(translations?.message?.expired ? translations.message.expired : "Expired");
      return;
    }

    const apiUrl = `${url}/api/method/frappe.automation.doctype.reminder.reminder.reminder`;
    const data = {
      "name": name,
      "description": textTitle,
      "disable": !isDisabled,
      "remind_at": date,
    };
    const apiKey = Cookies.get('api_key');
    const apiSecret = Cookies.get('api_secret');

    axios
      .put(apiUrl, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `token ${apiKey}:${apiSecret}`,
        },
      })
      .then((response) => {
        if (response?.data?.status_code === 202) {
          setSubPopup(true);
        }
        if (!isDisabled) {
          toast.success(translations?.message?.deactivatedSuccessfully ? translations.message.deactivatedSuccessfully : 'Deactivated  Successfully.'); // Show success toast for activation
        } else {
          toast.success(translations?.message?.activatedSuccessfully ? translations.message.activatedSuccessfully : 'Activated Successfully.'); // Show success toast for deactivation
        }
        onDeleteReminder();
      })
      .catch((error) => {
        if (error?.response?.data?.status_code === 202) {
          setSubPopup(true);
          setMessagePopup(error?.response?.data?.message)
        }
        toast.error(translations?.message?.failedDeactivateReminder ? translations.message.failedDeactivateReminder : 'Failed to deactivate the reminder.'); // Show error toast
      });

    setShowPopup(false);
  };
  const cancelDelete = () => {
    setShowPopup(false);
  };



  return (
    <div className="reminders-content">
      <Note
        url={url}
        date={formatDate(date)}
        textDate={textDate}
        title={title}
        textTitle={textTitle}
        title2={title2}
        para={para}
        showButton={showButton}
        titleLink={titleLink}
        translations={translations}
      />
      <div
        className="reminders-info"
      >
        <div className="reminders-btn" 
        onClick={ removeBtn !== (translations?.message?.expired ? translations.message.expired : "Expired") ? handleDelete : null} 
           disabled={removeBtn === (translations?.message?.expired ? translations.message.expired : "Expired")}     
           >
          <RemoveBtn name={buttonText} translations={translations} />
        </div>
        <RemainingDays days={days} hours={hours} translations={translations} />
      </div>



      <Modal isOpen={showPopup} onRequestClose={cancelDelete}>
        <ConfirmDelete
          cancleDelete={cancelDelete}
          confirmDelete={confirmDelete}
          para={isDisabled === 1 ? translations?.message?.areYouSureYouWantToActivateThis ? translations.message.areYouSureYouWantToActivateThis : "Are you sure you want to activate this?" :
            translations?.message?.areYouSureYouWantToDeActivateThis ? translations.message.areYouSureYouWantToDeActivateThis : "Are you sure you want to Deactivate this?"
          }
          deletePara={isDisabled === 1 ? (translations?.message?.activate ? translations.message.activate : "Activate") : (translations?.message?.deactivate ? translations.message.deactivate : "Deactivate")}
          isDisabled={isDisabled}
          translations={translations}
        />
      </Modal>
      <SubModal
          open={subPopup}
          onClose={handleCloseSubPopup}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
          >
          <Box className="subpopup-container">
                <p className='subpopup-text'>
                {
                    messagePopup ? messagePopup : "your subscription has expired"
                }
                </p>
                <a className='subpopup-link' href='/pricing'>Go To Pricing</a>
            </Box>
      </SubModal>
    </div>
  );
};

export default Reports;