import React from 'react'
import "./Home.css"
import { Hero, Covered, Features, Lawyers, HR } from "../../Features/Home"
import { ContactUs } from '../../Components'
import { Container } from 'react-bootstrap'

const Home = ({ url, data , translations , language}) => {


    const heroSection = data?.find(item => item.section === "Hero Section");
    const hrSection = data?.find(item => item.section === "HR Section");
    const coveredSection = data?.find(item => item.section === "Covered Section");
    const featuresSection = data?.find(item => item.section === "Features Section");
    const lawyersSection = data?.find(item => item.section === "Lawyers Section");
    const contactSection = data?.find(item => item.section === "Contact Us Section");

    return (
        <div className='home-page'>
            {heroSection && (
                <Hero url={url} heroSection={heroSection} />
            )}
            {hrSection && (
                <HR url={url} hrSection={hrSection} />
            )}
            {coveredSection && (
                <Covered coveredSection={coveredSection} />
            )}

            {featuresSection && (
                <Features url={url} featuresSection={featuresSection} />
            )}

            {lawyersSection && (
                <Lawyers url={url} lawyersSection={lawyersSection} />
            )}

            
            {contactSection && (
                <Container>
                    <ContactUs url={url} contactSection={contactSection}  translations={translations} language={language}/>
                </Container>
            )}

        </div>
    )
}

export default Home
