import React, { useState, useEffect } from 'react';
import './Reports.css';
import { AddBtn, DeletBtn, ConfirmDelete } from '../../../../Components';
import axios from 'axios';
import Modal from "react-modal"
import Cookies from 'js-cookie';
import rename from "../../../../assets/images/svg/rename.svg"
import { RenameReport ,MenuDots} from "../../../../Components";

import SubModal from '@mui/material/Modal';
import Box from '@mui/material/Box';

const Reports = ({ title, para, titleLink, url, name, onSuccess, topic, note, filters , date, translations}) => {

  const [showRegulationBtns, setShowRegulationBtns] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showRenameReport, setRenameReport] = useState(false);

  const [subPopup, setSubPopup] = useState(false);
  const [messagePopup, setMessagePopup] = useState("")   

  const handleCloseSubPopup = () => {
      setSubPopup(false);
  };

  var paratext = para.length > 10 ? para.slice(0, 10).join(', ') : para.slice(0, para.length).join(', ');
  const remainingCount = para.length > 10 ? para.length - 10 : 0;
  const handleDelete = () => {
    setShowPopup(true);
  };

  const handleShowBtnsClick = () => {
    if (isSmallScreen) {
      setShowRegulationBtns(!showRegulationBtns);
    }
  };


  const toggleReport = () => {
    setRenameReport(!showRenameReport);
  }


  const confirmDelete = () => {
    const apiUrl = `${url}/api/method/projectx.projectx.doctype.law_report.law_report.law_report?name=${name}`;
    const apiKey = Cookies.get('api_key');
    const apiSecret = Cookies.get('api_secret');

    axios
      .delete(apiUrl, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `token ${apiKey}:${apiSecret}`,
        },
      })
      .then((response) => {
        if (response?.data?.status_code === 202) {
          setSubPopup(true);
        }
        onSuccess();
      })
      .catch((error) => {
        if (error?.response?.data?.status_code === 202) {
          setSubPopup(true);
          setMessagePopup(error?.response?.data?.message)
        }
      });

    setShowPopup(false);
  };

  const cancelDelete = () => {
    setShowPopup(false);
  };



  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 992);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const timeString = date.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });

    return timeString !== "00:00"
      ? date.toLocaleString(undefined, {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
        })
      : date.toLocaleDateString(undefined, {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        });
  };

  return (
    <div className="reports-content ">
      
      <div className='reports-text'>
      <div class="report-info">
        <h1 class="note-date">{formatDate(date)}</h1>
        
       </div>

       
        {/* <h1 className='report-content'>Report Content</h1>
        <div className='reports-para'>
          <p>{paratext}  {remainingCount > 0 && <> +{remainingCount} more</>}</p>
        </div> */}
         <a href={`${titleLink}`} className='report-title-link'>
          <h1 className='report-title'>{title}</h1>
        </a>
      </div>
      {/* {isSmallScreen && (
        <div className='show-btns' onClick={handleShowBtnsClick}>

        </div>
      )} */}
      {(isSmallScreen && showRegulationBtns) || !isSmallScreen ? (
        <div className='report-btns'>
          <div onClick={toggleReport}>
            <AddBtn
              name={translations?.message?.renameReport ? translations.message.renameReport : "Rename Report"}
              img={rename}
            />
          </div>
          <div onClick={handleDelete}>
            <DeletBtn
              name={translations?.message?.deleteReport ? translations.message.deleteReport : "Delete Report"}
            />
          </div>
        </div>
      ) : null}

    {isSmallScreen && (
        <div className='show-btns' >
          <MenuDots 
            menuClass={"report-dots"}
            options={[
              <div onClick={toggleReport}>
              <AddBtn
                name={translations?.message?.renameReport ? translations.message.renameReport : "Rename Report"}
                img={rename}
              />
            </div>,
                      <div onClick={handleDelete}>
                      <DeletBtn
                        name={translations?.message?.deleteReport ? translations.message.deleteReport : "Delete Report"}
                      />
                    </div>
            ]}
          />
        </div>
      )}


      <Modal isOpen={showPopup} onRequestClose={cancelDelete}>
        <ConfirmDelete
          cancleDelete={cancelDelete}
          confirmDelete={confirmDelete}
          para= {translations?.message?.areSureDelete ? translations.message.areSureDelete : "Are you sure you want to delete this?"}
          deletePara={translations?.message?.yesDelete ? translations.message.yesDelete : "Yes, Delete"}
          translations={translations}
        />
      </Modal>

      <Modal isOpen={showRenameReport} onRequestClose={toggleReport}>
        <RenameReport
          cancelBtn={toggleReport}
          url={url}
          name={name}
          topic={topic}
          note={note}
          filters={filters}
          onSuccess={onSuccess}
          translations={translations}
        />
      </Modal>
      <SubModal
          open={subPopup}
          onClose={handleCloseSubPopup}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
          >
          <Box className="subpopup-container">
                <p className='subpopup-text'>
                {
                    messagePopup ? messagePopup : "your subscription has expired"
                }
                </p>
                <a className='subpopup-link' href='/pricing'>Go To Pricing</a>
            </Box>
      </SubModal>
    </div>
  );
};

export default Reports;