import React from 'react'
import "./Privacy.css"
import { ContactUs, Hero } from '../../Components'
import { Container, Row, Col } from "react-bootstrap"

const Privacy = ({data, translations}) => {
    const privacySection = data?.find(item => item.section === "Privacy Section");
    let privacy_date = "";
    let privacy_page_text = "";
    let privacy_title1 = "", privacy_title2 = "",
        privacy_title3 = "", privacy_title4 = "",
        privacy_title5 = "", privacy_title6 = "",
        privacy_title7 = "";

    let privacy_subtitle1 = "", privacy_subtitle2 = "",
        privacy_subtitle3 = "", privacy_subtitle4 = "",
        privacy_subtitle5 = "", privacy_subtitle6 = "",
        privacy_subtitle7 = "", privacy_end_text="";    

    if (privacySection) {
        ({
            privacy_date, privacy_page_text, privacy_title1, privacy_title2,
            privacy_title3, privacy_title3, privacy_title4, privacy_title5,
            privacy_title6, privacy_title7, privacy_subtitle1, privacy_subtitle2,
            privacy_subtitle3,privacy_subtitle4, privacy_subtitle5,privacy_subtitle6,
            privacy_subtitle7, privacy_end_text
        } = privacySection.data || {});

    }


    return (
        <div className='privacy-page'>
            <Hero 
                mainTitle={translations?.message?.home ? translations.message.home + " / ":"Home" + " / "}
                title={translations?.message?.privacyPolicy ? translations.message.privacyPolicy :"Privacy Policy"}
                para={translations?.message?.privacyPolicy ? translations.message.privacyPolicy :"Privacy Policy"}
            />
            <Container className='mt-5'>
                <div className='privacy-page-title'>{translations?.message?.privacyPolicy ? translations.message.privacyPolicy :"Privacy Policy"}</div>
                <div className='privacy-date'>{privacy_date}</div>
                <div className='privacy-page-text' dangerouslySetInnerHTML={{__html: privacy_page_text}}> 
                  {/* {privacySection.privacy_page_text} */}
                 </div>
                <div className='privacy'>
                 <div className='privacy-title'>{privacy_title1}</div>
                 <div className='privacy-text' dangerouslySetInnerHTML={{__html: privacy_subtitle1}}></div>
                 {/* <ul>
                    <li>Personal Information: This includes information that can identify you personally, such as your name, email address, phone number, and billing information.</li>
                    <li>Usage Information: We collect information about how you interact with our services, including your browsing activities, search queries, and interactions with our website or application.</li>
                    <li>Device Information: We may collect information about the device you use to access our services, including your device type, operating system, and unique device identifiers.</li>
                 </ul> */}
                </div>
                <div className='privacy'>
                 <div className='privacy-title'>{privacy_title2}</div>
                 <div className='privacy-text' dangerouslySetInnerHTML={{__html: privacy_subtitle2}} ></div>
                 {/* <ul>
                    <li>Providing and improving our services: We use your information to deliver our services to you, personalize your experience, and make improvements to our products and offerings.</li>
                    <li>Communicating with you: We may use your contact information to send you updates, newsletters, or marketing communications related to our services.</li>
                    <li>Analyzing usage trends: We analyze usage patterns and trends to understand how our services are being used and to improve user experience.</li>
                 </ul> */}
                </div>
                <div className='privacy'>
                 <div className='privacy-title'>{privacy_title3}</div>
                 <div className='privacy-text'  dangerouslySetInnerHTML={{__html: privacy_subtitle3}}></div>
                 {/* <ul>
                    <li>Service Providers: We may share your information with third-party service providers who assist us in providing our services, such as hosting providers, payment processors, and analytics services.</li>
                    <li>Legal Requirements: We may disclose your information if required to do so by law or in response to a valid legal request, such as a court order or subpoena.</li>
                    <li>Business Transfers: In the event of a merger, acquisition, or sale of our business or assets, we may transfer your information to the new owner or successor entity.</li>
                 </ul> */}
                </div>
                <div className='privacy'>
                 <div className='privacy-title'>{privacy_title4}</div>
                 <div className='privacy-text' dangerouslySetInnerHTML={{__html: privacy_subtitle4}}></div>
                 {/* <ul>
                    <li>Opt-Out: You may opt out of receiving marketing communications from us by following the unsubscribe instructions provided in our emails or by contacting us directly.</li>
                    <li>Cookies: You can set your browser to refuse all or some browser cookies, or to alert you when websites set or access cookies. However, if you disable or refuse cookies, some parts of our services may become inaccessible or not function properly.</li>
                 </ul> */}
                </div>
                <div className='privacy'>
                 <div className='privacy-title'>{privacy_title5}</div>
                 <div className='privacy-text' dangerouslySetInnerHTML={{__html: privacy_subtitle5}}></div>
                </div>

                <div className='privacy'>
                 <div className='privacy-title'>{privacy_title6}</div>
                 <div className='privacy-text' dangerouslySetInnerHTML={{__html: privacy_subtitle6}}></div>
                </div>

                <div className='privacy'>
                 <div className='privacy-title'>{privacy_title7}</div>
                 <div className='privacy-text' dangerouslySetInnerHTML={{__html: privacy_subtitle7}}></div>
                </div>

                <div className='privacy-text' style={{paddingTop:"40px"}}   dangerouslySetInnerHTML={{__html: privacy_end_text}}></div>

            </Container>
        </div>
        
    )
}

export default Privacy