import React from 'react'
import "./Footer.css"
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import logo from "../../Assets/images/svg/logo-dark.svg"
import location from "../../Assets/images/svg/location.svg"
import facebook from "../../Assets/images/footer/facebook.svg"
import instagram from "../../Assets/images/footer/instagram.svg"
import linkedin from "../../Assets/images/footer/linkedin.svg"
import twitter from "../../Assets/images/footer/twitter.svg"

const Footer = ({ translations}) => {
  return (
    <footer className="mt-5">
      <Container>
        <div className="footer-content">
          <div className="footer-text" id="footer-logo">
            <a href="/home"> <img src={logo} /></a>
            {/* <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum 
            </p> */}
          </div>
          <div className="footer-text" id="footer-links">
            <h3>{translations?.message?.learnMore ? translations.message.learnMore:"Learn More"}</h3>
            <a href="/about">{translations?.message?.aboutUs ? translations.message.aboutUs:"About us"}</a>
            <a href="/ourteam" className="">{translations?.message?.ourTeam ? translations.message.ourTeam:"Our Team"}</a>
            <a href={"/contact"}>{translations?.message?.contactUs ? translations.message.contactUs:"Contact us"}</a>
          </div>
          <div className="footer-text">
            <h3 className="">{translations?.message?.websiteMap ? translations.message.websiteMap:"Website map"}</h3>
            <a href="/pricing">
              {translations?.message?.pricing ? translations.message.pricing:"Pricing"}
            </a>
          </div>
          <div className="footer-text">
            <h3 className="text-uppercase">{translations?.message?.support ? translations.message.support:"support"}</h3>
            {/* <p>Terms & conditions</p> */}
            <a href="/privacy">{translations?.message?.privacyPolicy ? translations.message.privacyPolicy:"Privacy polic"}y</a>
          </div>
          <div className="footer-text" id="footer-touch">
            <h3>{translations?.message?.getInTouch ? translations.message.getInTouch:"get in touch"}</h3>
            <div className="footer-touch-text">
              <img className="footer-icon" src={location} />
              <p>{translations?.message?.canada ? translations.message.canada:"Canada"}</p>
            </div>
            <div className="footer-touch-email">
              {/* <h2>Email</h2>
              <input type="email" placeholder="Your Email" /> */}
            </div>
            <a href="/bookdemo">{translations?.message?.bookDemo ? translations.message.bookDemo:"Book Demo"}</a>
          </div>
        </div>
        <div className="footer-down">
          <div className="footer-links">
            <div className="links-text">
              <a href="/privacy">{translations?.message?.privacyPolicy ? translations.message.privacyPolicy:"Privacy Policy"}</a>
              {/* <Link to="/">Terms and Conditions</Link> */}
            </div>

            <div className="links">
              {/* <img src={twitter} />
              <img src={linkedin} />
              <img src={facebook} />
              <img src={instagram} /> */}
            </div>
          </div>
          <div className="footer-down-para">
            {/* <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptate, fuga. Ex at maxime eum odio quibusdam pariatur expedita explicabo harum! Consectetur ducimus delectus nemo, totam odit!</p> */}
            <p>{translations?.message?.copyright ? translations.message.copyright:"Copyright @ 2024. POLARIS"}</p>
          </div>
        </div>
      </Container>
    </footer>
  )
}

export default Footer