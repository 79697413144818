import React from 'react';
import "./SearchInput.css"
import close from "../../assets/images/svg/close.svg"

const SearchInput = ({ searchText, setSearchText, handleClearSearch, translations }) => {
  return (
    <div className="search-field">
      <input
        type="text"
        className="search-input"
        placeholder=  {translations?.message?.search ? translations.message.search+ "...": "Search..."}
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
      />
      {searchText && (
        <button className="search-input-btn" onClick={handleClearSearch}>
          <img src={close} alt="Close" />
        </button>
      )}
    </div>
  );
};

export default SearchInput;