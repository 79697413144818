import React, { useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import "./CheckEmail.css";
import { Container } from 'react-bootstrap';
import message from "../../assets/images/svg/message.svg";

const CheckEmail = ({ translations }) => {
  const location = useLocation();
  const email = location.state && location.state.email;
  const navigate = useNavigate();
  const [verificationCode, setVerificationCode] = useState(['', '', '', '', '', '']);
  const [error, setError] = useState('');
  const inputRefs = useRef([]);

  const handleVerifyEmail = async () => {
    if (verificationCode.some(code => code === '')) {
      setError(
        translations?.message?.enterCode
          ? translations.message.enterCode
          : 'Please enter the code.'
      );
      return;
    }

    const code = verificationCode.join('');

    try {
      const response = await fetch(`https://dashboard.polarisai.ca/api/method/frappe.core.doctype.user.user._get_user_for_update_password?key=${code}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          message: {
            user: null,
            message: "The reset password link has either been used before or is invalid",
          },
        }),
      });

      const data = await response.json();

      if (data.message === "The reset password link has either been used before or is invalid") {
        setError(data.message);
      } else {
        navigate(`/resetpassword/${email}/${code}`);
      }
    } catch (error) {
      setError('An error occurred while verifying the email. Please try again later.');
    }
  };

  const handleCodeChange = (index, value) => {
    const updatedCode = [...verificationCode];
    updatedCode[index] = value.slice(0, 1); // Ensure only one character per input
    setVerificationCode(updatedCode);

    // Move to the next input if a value is entered
    if (value !== '' && index < inputRefs.current.length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData('Text').slice(0, 6);
    const updatedCode = pastedData.split('').concat(Array(6 - pastedData.length).fill(''));
    setVerificationCode(updatedCode);

    // Set focus on the last filled input
    const lastFilledIndex = updatedCode.findIndex((digit) => digit === '');
    inputRefs.current[lastFilledIndex >= 0 ? lastFilledIndex : 5].focus();
  };

  const handleBackspace = (index) => {
    if (index > 0 && verificationCode[index] === '') {
      inputRefs.current[index - 1].focus();
    }
  };

  return (
    <div className='auth'>
      <div className='check-email-container'>
        <Container>
          <div className='check-email-image'>
            <img src={message} alt='Message' />
          </div>
          <h1 className='check-email-title'>
            {translations?.message?.checkYourEmail
              ? translations.message.checkYourEmail
              : "Check your email"}
          </h1>
          <p className='check-email-para'>
            {translations?.message?.sentVerificationCodeTo
              ? translations.message.sentVerificationCodeTo
              : "We sent a verification code to"}
          </p>
          <p className='check-email-para'>{email}</p>
          <div className='code-digits'>
            {verificationCode.map((digit, index) => (
              <input
                key={index}
                type='text'
                className='code-digit'
                maxLength={1}
                value={digit}
                onChange={(e) => handleCodeChange(index, e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Backspace') handleBackspace(index);
                }}
                onPaste={handlePaste}
                ref={(ref) => (inputRefs.current[index] = ref)}
                required
              />
            ))}
          </div>
          {error && <p className='error-message'>{error}</p>}
          <input
            type='submit'
            value='Verify Email'
            className='login-submit'
            onClick={handleVerifyEmail}
          />
          <div className='recive-code'>
            <p>
              {translations?.message?.dontReceiveCode
                ? translations.message.dontReceiveCode
                : "Don’t receive the code?"}
            </p>
            <a href='#'>
              {translations?.message?.resendCode
                ? translations.message.resendCode
                : "Resend the code"}
            </a>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default CheckEmail;
