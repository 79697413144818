import React from 'react'
import "./ConfirmAdd.css"
import add from "../../assets/images/svg/add_icon.svg"

const ConfirmAdd = ({ confirmAdd, cancleAdd, para, addPara, src, translations }) => {
  return (
    <div className='confirm-add-container'>
        <div className='confirm-add-content'>
            <img className='add-img' src={src??add} />
            <h1 className='add-title'>
            {translations?.message?.areSure ? translations.message.areSure : "Are you sure?"}
              
            </h1>
            <p className='add-para'>{para}</p>
        </div>
        <div className='confirm-btns'> 
            <button className='cancle-add' onClick={cancleAdd}>
            {translations?.message?.cancel ? translations.message.cancel : "Cancel"}
            </button>
            <button className='confirm-add' onClick={confirmAdd}>
              {addPara}</button>
        </div>
    </div>  
  )
}

export default ConfirmAdd