import React, { useState, useRef, useEffect } from 'react';
import "./Report.css";
import { AddBtn, NotePopUp, Note, ReminderPopUp, AddReport } from '../../../../Components';
import addBtn from "../../../../assets/images/svg/addbtn.svg";
import Modal from "react-modal";
import { MenuDots } from "../../../../Components";

const Report = ({ url, date, textDate, title, textTitle, title2, para, showButton, reportCount, noteCount, reminderCount, titleLink, federalRegulation, onRefresh, legId, translations }) => {

  const [showAddNote, setShowAddNote] = useState(false);
  const [showAddReminder, setShowAddReminder] = useState(false);
  const [showAmendmentsBtns, setShowAmendmentsBtns] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  const notePopUpRef = useRef(null);
  const reminderPopUpRef = useRef(null);
  const dateTimePickerRef = useRef(null);
  const showBtnsRef = useRef(null);  // Ref for the `show-btns` container
  const amendmentsBtnsRef = useRef(null);  // Ref for the `amendments-btns` container

  const handleAddNoteClick = () => {
    setShowAddNote(prev => !prev);
    setShowAddReminder(false); // Ensure the reminder popup is closed
    setShowPopup(false); // Ensure the report popup is closed
  };

  const handleAddReminderClick = () => {
    setShowAddReminder(prev => !prev);
    setShowAddNote(false); // Ensure the note popup is closed
    setShowPopup(false); // Ensure the report popup is closed
  };

  const togglePopup = () => {
    setShowPopup(prev => !prev);
    setShowAddNote(false); // Ensure the note popup is closed
    setShowAddReminder(false); // Ensure the reminder popup is closed
  };

  const handleNoteClickOutside = (event) => {
    if (notePopUpRef.current && !notePopUpRef.current.contains(event.target)) {
      setShowAddNote(false);
    }
  };

  const handleClickOutsideReminder = (event) => {
    const isClickInsideReminderPopup = reminderPopUpRef.current && reminderPopUpRef.current.contains(event.target);
    const isClickInsideDatePicker = dateTimePickerRef.current && dateTimePickerRef.current.contains(event.target);
    const isClickInsideDatePickerPopper = document.querySelector('[role="dialog"]')?.contains(event.target);

    if (!isClickInsideReminderPopup && !isClickInsideDatePicker && !isClickInsideDatePickerPopper) {
      setShowAddReminder(false);
    }
  };

  const handleClickOutside = (event) => {

        const isClickInsideDatePickerPopper = document.querySelector('[role="dialog"]')?.contains(event.target);
    if (
      showBtnsRef.current && !showBtnsRef.current.contains(event.target )  && !isClickInsideDatePickerPopper
    ) {
      setShowAmendmentsBtns(false);
    }
  };

  useEffect(() => {
    window.addEventListener("mousedown", handleClickOutsideReminder);
    return () => {
      window.removeEventListener("mousedown", handleClickOutsideReminder);
    };
  }, []);

  useEffect(() => {
    window.addEventListener("mousedown", handleClickOutside);
    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  useEffect(() => {
    window.addEventListener("mousedown", handleNoteClickOutside);
    return () => {
      window.removeEventListener("mousedown", handleNoteClickOutside);
    };
  }, []);
  
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 992);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    
  }, []);



  return (
    <div className="amendments-content">
      {isSmallScreen && (
        <div className='show-btns' >
          <MenuDots 
            menuClass={"report-dots"}
            options={[
              <div onClick={togglePopup}>
                <AddBtn
                  name= {translations?.message?.addToReport ? translations.message.addToReport : "Add to Report"}
                  count={reportCount}
                  img={addBtn}
                />
              </div>,
              <div onClick={handleAddNoteClick}>
                <AddBtn
                  name={translations?.message?.addNote ? translations.message.addNote : "Add Note"}
                  count={noteCount}
                  img={addBtn}
                />
              </div>,
              <div onClick={handleAddReminderClick}>
                <AddBtn
                  name={translations?.message?.addReminder ? translations.message.addReminder : "Add Reminder"}
                  count={reminderCount}
                  img={addBtn}
                />
              </div>,
            ]}
          />
        </div>
      )}
      <Note
        titleLink={titleLink}
        url={url}
        date={date}
        textDate={textDate}
        title={title}
        textTitle={textTitle}
        title2={title2}
        para={para}
        showButton={showButton}
        docName={federalRegulation}
        translations={translations}
      />
      <div className='amendments-btns'>
        <div ref={notePopUpRef} className={`NotePopUp ${showAddNote ? 'active' : ''}`}>
          <NotePopUp
            url={url}
            count={"4"} //todo this static?????
            isOpen={showAddNote}
            onCancel={handleAddNoteClick}
            docName={legId}
            onRefresh={onRefresh}
            translations={translations}
          />
        </div>
        <div ref={reminderPopUpRef} className={`ReminderPopUp ${showAddReminder ? 'active' : ''}`}>
          <ReminderPopUp
            url={url}
            count={"4"}//todo this static?????
            docName={legId}
            isOpen={showAddReminder}
            onCancel={handleAddReminderClick}
            onRefresh={onRefresh}
            dateTimePickerRef={dateTimePickerRef}
            translations={translations}
          />
        </div>
        {(!isSmallScreen || showAmendmentsBtns) && (
          <>
            <div onClick={togglePopup}>
              <AddBtn
                name= {translations?.message?.addToReport ? translations.message.addToReport : "Add to Report"}
                count={reportCount}
                img={addBtn}
              />
            </div>
            <div onClick={handleAddNoteClick}>
              <AddBtn
                name={translations?.message?.addNote ? translations.message.addNote : "Add Note"}
                count={noteCount}
                img={addBtn}
              />
            </div>
            <div onClick={handleAddReminderClick}>
              <AddBtn
                name={translations?.message?.addReminder ? translations.message.addReminder : "Add Reminder"}
                count={reminderCount}
                img={addBtn}
              />
            </div>
          </>
        )}
      </div>
      <Modal isOpen={showPopup} onRequestClose={togglePopup}>
        <AddReport
          cancelBtn={togglePopup}
          url={url}
          regulation={legId}
          translations={translations}
        />
      </Modal>
    </div>
  );
}

export default Report;
