import React, { useState, useEffect } from "react";
import "./Results.css";
import { AddResultBtn, Report, Paginations, AddReport, Debounce  } from "../../../../Components";
import axios from "axios";
import Modal from "react-modal";
import Skeleton from "react-loading-skeleton";
import Cookies from 'js-cookie';
import { toast } from "react-toastify";

import Box from '@mui/material/Box';
import SubModal from '@mui/material/Modal';

const Results = ({ url, selectedFilters, selectedDate, searchQuery, dashboardData, clearedDate, translations, language }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [data, setData] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const resultsPerPage = 20;
  const [loading, setLoading] = useState(true);
  const [enableSearch, setEnableSearch] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);


  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const [subPopup, setSubPopup] = useState(false);
     
  const [messagePopup, setMessagePopup] = useState("")
    const handleCloseSubPopup = () => {
        setSubPopup(false);
    };


  useEffect(() => {
    // Extract the initial data from dashboardData
    const regulationSection = dashboardData?.find(
      (section) => section.section === "Regulation List"
    );
    if (regulationSection) {
      const initialData = regulationSection?.data?.content?.regulations?.objects??[];
      const pagination = regulationSection?.data?.content?.regulations?.pagination?.metadata?? null;
      setData(initialData);
      setCurrentPage(pagination?.current_page??1);
      setTotalPages(pagination?.total_pages??1);
      setLoading(false);
    }
  }, [dashboardData]);

  const debouncedSearchQuery = Debounce(searchQuery, 500); // 500ms debounce delay


  useEffect(() => {
    
    if ((selectedFilters != {} && selectedFilters != undefined && Object.keys(selectedFilters).length > 0) || (selectedDate && (selectedDate.startDate !== null || selectedDate.endDate !== null)) || (clearedDate && clearedDate === true )) {
      fetchData();
    }
  }, [selectedFilters,selectedDate,clearedDate]);


  useEffect(() => {
    if (debouncedSearchQuery.length > 0) {
      fetchData();
    }
  }, [debouncedSearchQuery]);

  useEffect(() => {
    if (searchQuery === "" && debouncedSearchQuery.length > 0) {
      fetchData();
    }
  }, [searchQuery]);

  // useEffect(() => {
  //   fetchData();
  // }, [])

  const fetchData = async (pageNumber = 1) => {

    const filterParams = Object.keys(selectedFilters)
    .filter((type) => selectedFilters[type].length > 0)
    .map((type) => {
      if (Array.isArray(selectedFilters[type])) {
        return `${type}=${encodeURIComponent(
          selectedFilters[type].join(",")
        )}`;
      } else {
        return `${type}=${encodeURIComponent(selectedFilters[type])}`;
      }
    })
    .join("&");

    // if((enableSearch == false && searchQuery) || (!searchQuery) || (!filterParams)){
    //   setEnableSearch(true);
    //   console.log("BBBBBBBBBBBBB")
    //   return;
    // }
    // if (enableSearch == false  && searchQuery){
      try {

  
        const start = (pageNumber - 1) * resultsPerPage;
        // Construct the URL with or without search query
        let apiUrl = `${url}/api/method/projectx.projectx.doctype.federal_regulation.federal_regulation.get_regulation_list?start=${start}&limit=${resultsPerPage}${
          searchQuery ? `&search=${encodeURIComponent(searchQuery)}` : ""
        }${filterParams ? `&${filterParams}` : ""}`;
        if (selectedDate && selectedDate.startDate && selectedDate.endDate) {
          apiUrl += `&inforce_start_date=${selectedDate.startDate}&inforce_end_date=${selectedDate.endDate}`;
        }
        const apiKey = Cookies.get("api_key");
        const apiSecret = Cookies.get("api_secret");
        setLoading(true); // Start loading
        const response = await axios.get(apiUrl, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${apiKey}:${apiSecret}`,
          },
        });
        if (response.data.status_code !== 200) {
          toast.error(translations?.message?.failedFetchData ? translations.message.failedFetchData :"Failed to fetch data. Please try again..");
        }

        if (response?.data?.status_code === 202) {
          setSubPopup(true);
        }
  
        const result = response.data.result;
  
  
        setCurrentPage(result.pagination.metadata.current_page);
        setTotalPages(result.pagination.metadata.total_pages);
        setData(result.objects);
        setLoading(false); // End loading
      } catch (error) {
        if (error?.response?.data?.status_code === 202) {
          setSubPopup(true);
          setMessagePopup(error?.response?.data?.message)
        }
        toast.error(translations?.message?.failedFetchData ? translations.message.failedFetchData :"Failed to fetch data. Please try again..");
        setLoading(false); // End loading
      }
    // }
    // #esraa fix filter after content by page filters.
    // else {
    //   setEnableSearch(true);
    // }
    
  };

  const onPageChange = (pageNumber) => {
    fetchData(pageNumber);
  };


  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 992);
    };
    window.addEventListener("resize", handleResize);
    handleResize(); // Check initial screen width on component mount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="results">
      {loading ? (
        <>
          <div className="regulation-skeleton">
            <div className="first-regulation-skeleton">
              <Skeleton width={"100%"} height={17} />
              <Skeleton width={"100%"} height={17} />
            </div>
            <div className="second-regulation-skeleton">
              <Skeleton width={"100%"} height={16} />
              <Skeleton width={"100%"} height={16} />
              <Skeleton width={"100%"} height={16} />
              <Skeleton width={"70%"} height={16} />
            </div>
          </div>
          <br />
          <div className="regulation-skeleton">
            <div className="first-regulation-skeleton">
              <Skeleton width={"100%"} height={17} />
              <Skeleton width={"100%"} height={17} />
            </div>
            <div className="second-regulation-skeleton">
              <Skeleton width={"100%"} height={16} />
              <Skeleton width={"100%"} height={16} />
              <Skeleton width={"100%"} height={16} />
              <Skeleton width={"70%"} height={16} />
            </div>
          </div>
          <br />
          <div className="regulation-skeleton">
            <div className="first-regulation-skeleton">
              <Skeleton width={"100%"} height={17} />
              <Skeleton width={"100%"} height={17} />
            </div>
            <div className="second-regulation-skeleton">
              <Skeleton width={"100%"} height={16} />
              <Skeleton width={"100%"} height={16} />
              <Skeleton width={"100%"} height={16} />
              <Skeleton width={"70%"} height={16} />
            </div>
          </div>
          <br />
        </>
      ) : (
        <>
          {!data || data.length === 0 ? (
            <div className="not-found">{translations?.message?.noRegulations ? translations.message.noRegulations: "There are no regulations."}</div>
          ) : (
            <>
              <div onClick={togglePopup}>
                <AddResultBtn translations={translations}/>
              </div>
              <Modal
                isOpen={showPopup}
                onRequestClose={togglePopup}
                contentLabel="Select Filter Popup"
              >
                <AddReport
                  url={url}
                  cancelBtn={togglePopup}
                  selectedFilters={selectedFilters}
                  showAddNew={true}
                  start={(currentPage - 1) * resultsPerPage}
                  limit={resultsPerPage}
                  inforce_start_date={selectedDate?.startDate?? null}
                  inforce_end_date={selectedDate?.endDate?? null}
                  translations={translations}
                />
              </Modal>

              {data.map((item, index) => (
                <Report
                  key={item.name}
                  index={index}
                  titleLink={`/dashboard/regulation/${encodeURIComponent(item.id)}`}
                  property={
                    item?.areas_of_laws
                      ? item.areas_of_laws
                          .split(",")
                          .map((area) => area.trim())
                          .join(", ")
                      : ""
                  }
                  title={item.title}
                  para={
                    item.long_title
                  }
                  noteCount={item.notes_count}
                  reminderCount={item.reminders_count}
                  url={url}
                  onRefresh={fetchData}
                  noteRegulation={item.regulation_type}
                  isSmallScreen={isSmallScreen}
                  legId={item.id}
                  translations={translations}
                  language={language}
                />
              ))}
              <div className="d-flex justify-content-center mt-5">
                <Paginations
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={onPageChange}
                />
              </div>
            </>
          )}
        </>
      )}
       <SubModal
                open={subPopup}
                onClose={handleCloseSubPopup}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
                >
                <Box className="subpopup-container">
                    <p className='subpopup-text'>
                    {
                        messagePopup ? messagePopup : "your subscription has expired"
                    }
                    </p>
                    <a className='subpopup-link' href='/pricing'>Go To Pricing</a>
                </Box>
          </SubModal>
    </div>
  );
};

export default Results;
