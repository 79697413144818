import React from 'react'
import "./AddResultBtn.css"
import addBtn from "../../assets/images/svg/addbtn.svg"

const AddResultBtn = ({translations}) => {
    return (
        <button className='add-result'>
        <img src={addBtn} />
        {translations?.message?.addResultToReport ? translations.message.addResultToReport : "Add This Results to Report"} 
        </button>
    )
}

export default AddResultBtn
