import React, { useState } from 'react';
import './ReminderPopUp.css';
import axios from 'axios';
import Cookies from 'js-cookie';
import global from '../../assets/images/svg/global.svg';
import DatePickers from '../DatePickers/DatePickers';
import { DateTimePickerValue } from '../index';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import dayjs from 'dayjs';



import Box from '@mui/material/Box';
import SubModal from '@mui/material/Modal';

const ReminderPopUp = ({ count, isOpen, onCancel, url, docName, onRefresh,dateTimePickerRef, translations, language }) => {

  const [value, setValue] = useState(null);
  const handleDateTimeChange = (newValue) => {
    setValue(newValue);
    setErrors({}); // Reset errors when date changes
  }; 
  const handleCancel = () => {
    onCancel();
  };

  const [reminderText, setReminderText] = useState("");
  const [reminderDate, setReminderDate] = useState("");
  const [errors, setErrors] = useState({}); // Error state object


  const [subPopup, setSubPopup] = useState(false);
  const [messagePopup, setMessagePopup] = useState("")
     
  const handleClose = () => {
      setSubPopup(false);
  };

  const handleReminderDateChange = (date) => {
    setReminderDate(date);
    setErrors({}); // Reset errors when date changes
  };

  const handleSave = () => {
    if (!value) {
      setErrors({ date: translations?.message?.pleaseChooseDate ? translations.message.pleaseChooseDate:"Please choose a date" });
      return;
    }
  
    if (!reminderText) {
      setErrors({ text: translations?.message?.pleaseEnterReminderTitle ? translations.message.pleaseEnterReminderTitle: "Please enter a reminder title" }); // Set error for reminder text field
      return;
    }
 
   const currentDateTime = new Date();
  
    if (value < currentDateTime) {
      setErrors({ date: translations?.message?.pleaseChooseDateAfterCurrentDate ? translations.message.pleaseChooseDateAfterCurrentDate:"Please choose a date after the current date" }); // Set error for date field
      return;
    }

    //  const pathSegments = window.location.pathname.split('/');
    //  const lastSegment = pathSegments[pathSegments.length - 1];
    //  let reminderDoctype;
    //  if (["regulation", "amendments","search","dashboard"].includes(lastSegment)  || window.location.pathname.includes('/dashboard/reports/')  || window.location.pathname.includes('/dashboard/regulation/'))  {
    //    reminderDoctype = `${docType}`;
    //  } 


    const data = {
      remind_at: value.format('YYYY-MM-DD HH:mm:ss'), 
      description: reminderText,
      reminder_doctype: "Legislation",
      reminder_docname: `${docName}`
    };
  
    const apiUrl = `${url}/api/method/frappe.automation.doctype.reminder.reminder.reminder`;
    const apiKey = Cookies.get('api_key');
    const apiSecret = Cookies.get('api_secret');
  
    axios
      .post(
        apiUrl,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `token ${apiKey}:${apiSecret}`
          },
        }
      )
      .then(response => {

        if (response?.data?.status_code === 202) {
          setSubPopup(true);
        }

        onCancel(); // Close the component after saving
        const formattedReminderDate = data.remind_at.toLocaleString();
        toast.success(translations?.message?.yourSavedReminderFor ? translations.message.yourSavedReminderFor + formattedReminderDate: `You saved a reminder for ${formattedReminderDate}`);
        setReminderText("");
        setReminderDate("");
        if(onRefresh){
          onRefresh();
        }
      })
      .catch(error => {
        if (error?.response?.data?.status_code === 202) {
          setSubPopup(true);
          setMessagePopup(error?.response?.data?.message)
        }
        console.error(error);
        toast.error(translations?.message?.failedSaveReminder ? translations.message.failedSaveReminder:'Failed to save the reminder.');
      });
  };

  return (
    <div className={`reminder-pop-up ${isOpen ? "active" : ""}`}>
      <div className="reminder-header">
        <div className="global-reminders">
          <img className="reminder-header-image" src={global} alt="Global" />
          <h1 className="reminder-header-title">
          {translations?.message?.addReminder ? translations.message.addReminder : "Add Reminder"}
            </h1>
          {/* <h1 className="reminder-header-count">{count}</h1> */}
        </div>
        <Link reloadDocument to="/dashboard/reminders" className="see-all-reminders">
          <h1 className="see-all-reminders-title">
          {translations?.message?.seeAllReminders ? translations.message.seeAllReminders : "See All reminders"}
            </h1>
        </Link>
      </div>
      <div className="reminder-text">
        <input
          type="text"
          placeholder={translations?.message?.reminderTitle ? translations.message.reminderTitle :"Reminder title"}
          className="reminder-title-text"
          value={reminderText}
          onChange={(e) => setReminderText(e.target.value)}
        />
        {errors.text && <p className="popup-error">{errors.text}</p>}{" "}
        {/* Display error for text field */}
        {/* <DatePickers onDateChange={handleReminderDateChange} inputType="date" /> */}   
        <DateTimePickerValue
          handleDateTimeChange={handleDateTimeChange} 
          value={value} 
          ref={dateTimePickerRef}
          language={language}
        />
        {errors.date && <p className="popup-error">{errors.date}</p>}{" "}
        {/* Display error for date field */}
      </div>
      <div className="reminder-footer">
        <div>
          {/* <button className="reminder-delete-btn">Delete</button> */}
        </div>
        <div>
          <button className="reminder-cancel-btn" onClick={handleCancel}>
          {translations?.message?.cancel ? translations.message.cancel : "Cancel"}
          </button>
          <button className="reminder-save-btn" onClick={handleSave}>
          {translations?.message?.save ? translations.message.save : "Save"}
          </button>
        </div>
      </div>

        <SubModal
              open={subPopup}
              onClose={handleClose}
              aria-labelledby="child-modal-title"
              aria-describedby="child-modal-description"
              >
              <Box className="subpopup-container">
                    <p className='subpopup-text'>
                    {
                        messagePopup ? messagePopup : "your subscription has expired"
                    }
                    </p>
                    <a className='subpopup-link' href='/pricing'>Go To Pricing</a>
                </Box>
          </SubModal>

    </div>
  );
};

export default ReminderPopUp;
